import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Data} from '@app/core';
import { ShopService, ProfileService, UtilService, RecordingService } from '@app/core/services';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

@Component({
  selector: 'app-showcase-modal',
  templateUrl: './showcase-modal.component.html',
  styleUrls: ['./showcase-modal.component.scss'],
})
export class ShowcaseModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  modalRef = null;
  @Input() recordingTitle = "default";
  @Input() recordingObject;
  user: any = null;
  count = 100;
  anonymous = false;
  message = '';
  isSending = false;
  sent = false;
  notEnough = false;
  errorMessage = null;
  loggedInUser: any = null;

  constructor(
    private modalService: NgbModal,
    private shopService: ShopService,
    private toastr: ToastrService,
    private router: Router,
    private profileService: ProfileService,
    private emailNotificationsService: EmailNotificationsService,
    private utilService: UtilService,
    private recordingService: RecordingService,
    private data: Data
  ) {}

  ngOnInit() {
    this.loggedInUser = this.profileService.user;
    console.log('recobject', this.recordingObject);
  }

  open(user) {
    this.user = user;
    this.anonymous = false;
    this.message = '';
    this.count = 5;
    this.sent = false;
    this.notEnough = false;
    this.openModal();
  }

  close() {
    this.modalRef.close();
  }

  changeCount(value) {
    this.count = value;
  }

  changeAnonymous(value) {
    this.anonymous = value;
    if (value) {
      this.message = '';
    }
  }

  handlePurchase() {
    this.router.navigate(['/d/payments/credits']);
    this.modalRef.close();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal showcase-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then(result => {}, async reason => {});
  }

  async addShowcaseRecording(recording) {
    if (this.profileService.user.credits < this.count) {
      this.notEnough = true;
      return;
    }
    this.isSending = true;
    this.recordingService.showcaseRecording(recording.id).subscribe(response => {
      if (response.success) {
        this.sent=true
        this.profileService.getUserProfile().toPromise();
        this.sendEmail();
      }
         if (!response.success) {
        this.errorMessage = response.message
        this.sent=false;
      }
      this.isSending = false;
     }, err => {
      console.log(err)
      this.toastr.error('Error: ' + err.error.message);
      this.isSending = false;
     })
}


  async handleSubmit() {
    if (this.profileService.user.credits < this.count) {
      this.notEnough = true;
      return;
    }
    try {
      this.isSending = true;
      await this.shopService
        .sendCredits(this.user.id, this.count, this.anonymous, this.message)
        .toPromise();
      this.sent = true;
      await this.profileService.getUserProfile().toPromise();
    } catch (err) {
      this.toastr.error('Failed to Showcase ' + this.user.screen_name + "'s recording");
    } finally {
      this.isSending = false;
    }
  }

  sendEmail(){

    let spic = this.data.removeHTTP(this.loggedInUser.photo);
    let vpic = this.data.removeHTTP(this.recordingObject.cover);
    let vrec = this.data.removeHTTP(this.recordingObject.uri);
    let messageLeft = "";

    if(this.recordingObject.user.newsletter){
      this.emailNotificationsService.emailJustShowcased(
        this.recordingObject.user.email,
        this.recordingObject.user.screen_name,
        this.loggedInUser.screen_name,
        this.loggedInUser.id,
        this.recordingObject.song.title,
        this.recordingObject.song.share_url,
        spic,
        vpic,
        vrec,
        messageLeft,
        false
      )
    }
  }

}
